/* eslint-disable no-shadow */
import Vue from 'vue'
import Vuex from 'vuex'
import mask from './modules/mask'
import login from './modules/login'
import broker from './modules/broker'
import cc from './modules/cc'
import ccBeneficiary from './modules/ccBeneficiary'
import brokerEntityForm from './modules/brokerEntityForm'
import brokerForm from './modules/brokerForm'
import countrySelection from './modules/countrySelection'
import personStepOne from './modules/personData/stepOne'
import personStepTwo from './modules/personData/stepTwo'
import personStepThree from './modules/personData/stepThree'
import personStepFour from './modules/personData/stepFour'
import personStepFive from './modules/personData/stepFive'
import entityStepOne from './modules/entityData/stepOne'
import entityStepTwo from './modules/entityData/stepTwo'
import entityStepThree from './modules/entityData/stepThree'
import entityStepFour from './modules/entityData/stepFour'
import entityStepFive from './modules/entityData/stepFive'
import entityStepSix from './modules/entityData/stepSix'
import layout from './modules/layout'
import { getRealtimeRateTable } from '@/api/root'
import { getAvailableCurrency } from '@/api/common'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    realRates: [],
    availableCurrency: [],
    isMobile: false,
    canScroll: true,
    showReferrerLogin: false
  },
  mutations: {
    SetRealEXRates (state, payload) {
      state.realRates = payload
    },
    SetAvailableCurrency (state, payload) {
      state.availableCurrency = payload
    },
    SetIsMobile (state, payload) {
      state.isMobile = payload
    },
    SetScroll (state, payload) {
      state.canScroll = payload
    },
    SetShowReferrerLogin (state) {
      state.showReferrerLogin = true
    }
  },
  actions: {
    ShowReferrerLogin (context) {
      context.commit('SetShowReferrerLogin')
    },
    GetRealEXRatesAction (context, payload) {
      getRealtimeRateTable().then((res) =>
        context.commit('SetRealEXRates', res)
      )
    },
    GetavailableCurrencyAction (context) {
      getAvailableCurrency().then((res) => {
        context.commit('SetAvailableCurrency', res)
      }).catch(e => {
        context.commit('SetAvailableCurrency', [])
      })
    }
  },
  getters: {
    getFileList: ({ personStepSeven: { fileList } }) => {
      return fileList
    },
    getAllPersonFormData: ({ personStepOne, personStepTwo, personStepThree, personStepFour, personStepFive }, getters) => {
      return { ...personStepOne, ...personStepTwo, ...personStepThree, ...personStepFour, ...personStepFive, tax_residence_us_person: getters['personStepOne/has_us_taxer'] }
    },
    availableCurrencyList: state => {
      return state.availableCurrency
    },
    availableCurrencyListShortCode: state => {
      return (state.availableCurrency).map(item => item.shortCode)
    }
  },
  modules: {
    mask,
    login,
    personStepOne,
    personStepTwo,
    personStepThree,
    personStepFour,
    personStepFive,
    entityStepOne,
    entityStepTwo,
    entityStepThree,
    entityStepFour,
    entityStepFive,
    entityStepSix,
    countrySelection,
    layout,
    brokerForm,
    broker,
    brokerEntityForm,
    cc,
    ccBeneficiary
  }
})
