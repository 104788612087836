import { getCCStatus, getCCBalance } from '@/api/common'
// import { message } from 'ant-design-vue'
const cc = {
  namespaced: true,
  state: {
    ccAbility: false, // 是否有资格开通cc
    ccStatus: '', // pending enabled false
    balance: JSON.parse(localStorage.getItem('ccBalance')) || [],
    pageSpinner: false,
    // balance: [],
    customer: JSON.parse(localStorage.getItem('ccCustomer')) || {}
  },
  mutations: {
    SetCCAbilityMut (state, payload) {
      state.ccAbility = payload
    },
    SetCCStatusMut (state, payload) {
      state.ccStatus = payload
    },
    SetBalanceMut (state, payload) {
      state.balance = payload
      localStorage.setItem('ccBalance', JSON.stringify(payload))
    },
    SetCustomerMut (state, payload) {
      state.customer = payload
      localStorage.setItem('ccCustomer', JSON.stringify(payload))
    },
    SetSpiningMut (state, payload) {
      state.pageSpinner = payload
    }
  },
  actions: {
    fetchCCOpenStatus (context, payload) {
      const defaultBalance = [
        {
          id: 1,
          currency: 'AUD',
          amount: '0.00'
        },
        {
          id: 2,
          currency: 'NZD',
          amount: '0.00'
        },
        {
          id: 3,
          currency: 'USD',
          amount: '0.00'
        }
      ]
      context.commit('SetSpiningMut', true)
      context.commit('SetCCAbilityMut', true) // 如果能走到这里 说明 skyline 已经通过了
      const customerId = payload.customer_id
      return getCCStatus({ customer_id: customerId }).then((res) => {
        if (res?.status) {
          if (res.status === 'pending') {
            context.commit('SetCCStatusMut', res.status)
          } else if (res.status === 'enabled') {
            context.commit('SetSpiningMut', true)
            context.commit('SetCCStatusMut', res.status)
            return getCCBalance({ customer_id: customerId })
              .then((res) => {
                if (res?.balance) {
                  if (res.balance.length === 0) {
                    context.commit('SetBalanceMut', defaultBalance)
                  } else {
                    context.commit('SetBalanceMut', res.balance)
                  }
                }
                if (res?.customer) {
                  context.commit('SetCustomerMut', res.customer)
                }
              })
              .catch((error) => {
                return new Promise((resolve, reject) => {
                  return reject(error)
                })
              })
              .finally(() => {
                context.commit('SetSpiningMut', false)
              })
          } else {
            context.commit('SetCCStatusMut', 'able to open')
          }
        } else {
        }
      }).catch(() => {
        context.commit('SetCCStatusMut', false)
      }).finally(() => {
        context.commit('SetSpiningMut', false)
      })
    },
    RestCCDetails (context) {
      context.commit('SetBalanceMut', [])
      context.commit('SetCustomerMut', {})
    },
    RestCCStatus (context) {
      context.commit('SetCCAbilityMut', false)
      context.commit('SetCCStatusMut', '')
    }
  },
  getters: {
    isCCactive: (state) => {
      if (state?.customer?.id) {
        console.log('id:' + state?.customer?.id)
        return true
      } else {
        return false
      }
    },
    getSpinning: (state) => {
      return state.pageSpinner
    }
  }
}
export default cc
