import axios from 'axios'
import { getLocalStorage } from '@/utils/localStorage'
import QS from 'qs'

const baseUrl = process.env.VUE_APP_BASE_FORM_URL
const DcatBase = axios.create({
  baseURL: baseUrl,
  timeout: 8000
})

DcatBase.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (config.headers.post['Content-Type'] === 'application/x-www-form-urlencoded') {
        // config.data = QS.stringify(config.data)
      }
    }
    return config
  },
  error => {
    return Promise.error(error)
  }
)

DcatBase.interceptors.response.use(
  // axios 响应拦截
  response => {
    if (response.status === 200) {
      if (response.data.error_code === 0 && response.data.message === 'OK') {
        return Promise.resolve(response.data.data)
      } else if (response.data.error_code === 103) {
        return Promise.reject(response.data)
      } else {
        return Promise.reject(response.data)
      }
    } else {
      return Promise.reject(response.data)
    }
  },
  error => {
    console.log(error)
    // removeLocalStorage('token')
    // Router.push('/login')
    return Promise.reject(error.data)
  }
)

export function newPost (url, params, auth = false, Qs = true) {
  const tokenHeader = {
    headers: {
      Authorization: 'Bearer ' + JSON.parse(getLocalStorage('token'))?.access_token
    }
  }
  if (!auth) {
    if (Qs) {
      return new Promise((resolve, reject) => {
        DcatBase.post(url, QS.stringify(params)).then(
          res => {
            resolve(res)
          }
        ).catch(err => {
          reject(err)
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        DcatBase.post(url, params).then(
          res => {
            resolve(res)
          }
        ).catch(err => {
          reject(err)
        })
      })
    }
  } else {
    return new Promise((resolve, reject) => {
      DcatBase.post(url, params, tokenHeader).then(
        res => {
          resolve(res)
        }
      ).catch(err => {
        reject(err)
      })
    })
  }
}
export default { newPost }
