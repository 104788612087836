/* eslint-disable brace-style */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index.vue'
import IeMoney from '@/views/home/subPage/IeMoney.vue'
import { hasLocalStorage, getLocalStorage, removeLocalStorage } from '@/utils/localStorage'
import { getCustomerProfile } from '@/api/common'
import { message } from 'ant-design-vue'
Vue.use(VueRouter)
Vue.use(message)

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      roles: ['white']
    },
    children: [
      {
        path: '',
        component: IeMoney,
        name: 'homePage',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'about',
        component: () => import('@/views/home/subPage/About.vue'),
        name: 'about',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'affiliates',
        component: () => import('@/views/home/subPage/Affiliates.vue'),
        name: 'Affiliates',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'affiliates/affiliates_sign',
        component: () => import('@/views/home/subPage/AffiliatesSign.vue'),
        name: 'AffiliatesSign',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'contact',
        component: () => import('@/views/home/subPage/Contact.vue'),
        name: 'Contact',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'forgot_password',
        component: () => import('@/views/home/subPage/ForgotPwd.vue'),
        name: 'ForgotPwd',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'faq',
        component: () => import('@/views/home/subPage/FAQ.vue'),
        name: 'FAQ',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'products-solutions',
        component: () => import('@/views/home/subPage/Products.vue'),
        name: 'Products',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'sms-login',
        component: () => import('@/views/home/subPage/SmsLogin.vue'),
        name: 'SmsLogin',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'privacy-policy',
        component: () => import('@/views/home/subPage/PrivacyPolicy.vue'),
        name: 'PrivacyPolicy',
        meta: {
          roles: ['white']
        }
      },
      {
        path: 'terms-of-service',
        component: () => import('@/views/home/subPage/TermsOfService.vue'),
        name: 'TermsOfService',
        meta: {
          roles: ['white']
        }
      }
    ]
  },
  {
    path: '/signpage',
    component: () => import('@/views/SignPage.vue'),
    name: 'SignPage',
    meta: {
      roles: ['white']
    }
  },
  {
    path: '/user',
    component: () => import('@/views/user/index.vue'),
    name: 'UserDash',
    meta: {
      roles: ['user', 'entity']
    },
    children: [
      {
        path: '',
        component: () => import('@/views/user/subPage/Profile.vue'),
        name: 'UserPage',
        meta: {
          roles: ['user']
        }
        // default page
      },
      {
        path: 'cc-account',
        name: 'CCAccount',
        component: () => import('@/components/User/ProfileDash.vue')
      },
      {
        path: 'vertify',
        component: () => import('@/views/user/subPage/Vertify.vue'),
        name: 'Veritify',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'convert-list',
        component: () => import('@/views/user/subPage/CurrencyConvert/ConvertList.vue'),
        name: 'ConvertList',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'convert',
        component: () => import('@/views/user/subPage/CurrencyConvert/Convert.vue'),
        name: 'Convert',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'convert-confirm',
        component: () => import('@/views/user/subPage/CurrencyConvert/CovertConfirm.vue'),
        name: 'ConvertConfirm',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'beneficiary',
        component: () => import('@/views/user/subPage/CurrencyBeneficiary/BeneficiaryList.vue'),
        name: 'BeneficiaryList',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'beneficiary/add',
        component: () => import('@/views/user/subPage/CurrencyBeneficiary/Add/BeneficiaryAddLayout.vue'),
        name: 'BeneficiaryAdd',
        meta: {
          roles: ['user']
        },
        children: [
          {
            path: '',
            component: () => import('@/views/user/subPage/CurrencyBeneficiary/Add/BeneficiaryAddStepOne.vue'),
            name: 'BeneficiaryAddStepOne',
            meta: {
              roles: ['user']
            }
          },
          {
            path: 'step-two',
            name: 'BeneficiaryAddStepTwo',
            component: () => import('@/views/user/subPage/CurrencyBeneficiary/Add/BeneficiaryAddStepTwo.vue')
          },
          {
            path: 'step-three',
            name: 'BeneficiaryAddStepThree',
            component: () => import('@/views/user/subPage/CurrencyBeneficiary/Add/BeneficiaryAddStepThree.vue')
          },
          {
            path: 'step-four',
            name: 'BeneficiaryAddStepFour',
            component: () => import('@/views/user/subPage/CurrencyBeneficiary/Add/BeneficiaryAddStepFour.vue')
          }
        ]
      },
      {
        path: 'payment-list',
        component: () => import('@/views/user/subPage/CurrencyPayment/PaymentList.vue'),
        name: 'CCPaymentList',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'payment-create',
        component: () => import('@/views/user/subPage/CurrencyPayment/PaymentCreate.vue'),
        name: 'CCPaymentCreate',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'bank',
        component: () => import('@/views/user/subPage/Bank.vue'),
        name: 'Bank',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'profile',
        component: () => import('@/views/user/subPage/Profile.vue'),
        name: 'Profile',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'promote',
        component: () => import('@/views/user/subPage/Promote.vue'),
        name: 'Promote',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'transition',
        component: () => import('@/views/user/subPage/Transition.vue'),
        name: 'Transition',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'liverate',
        component: () => import('@/views/user/subPage/LiveRate.vue'),
        name: 'LiveRate',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'createorder',
        component: () => import('@/views/user/subPage/CreateOrder.vue'),
        name: 'CreateOrder',
        meta: {
          roles: ['user']
        }
      },
      {
        path: 'dashboard',
        component: () => import('@/views/user/subPage/DashBoard.vue'),
        name: 'DashBoard',
        meta: {
          roles: ['user']
        }
      },
      {
        path: '/user/personapplication',
        component: () => import('@/views/user/subPage/PersonApply.vue'),
        name: 'PersonApplication',
        meta: {
          roles: ['user']
        },
        children: [
          {
            path: '1',
            component: () => import('@/views/user/subPage/PersonApply/StepOne'),
            name: 'PersonApplicationOne',
            meta: {
              roles: ['user']
            }
          },
          {
            path: '2',
            component: () => import('@/views/user/subPage/PersonApply/StepTwo'),
            name: 'PersonApplicationTwo',
            meta: {
              roles: ['user']
            }
          // }, {
          //   path: '3',
          //   component: () => import('@/views/user/subPage/PersonApply/StepThree'),
          //   name: 'PersonApplicationThree',
          //   meta: {
          //     roles: ['user']
          //   }
          }, {
            path: '3',
            component: () => import('@/views/user/subPage/PersonApply/StepFour'),
            name: 'PersonApplicationFour',
            meta: {
              roles: ['user']
            }
          }, {
            path: '4',
            component: () => import('@/views/user/subPage/PersonApply/StepFive'),
            name: 'PersonApplicationFive',
            meta: {
              roles: ['user']
            }
          }
        ]
      },
      {
        path: '/user/entityapplication',
        component: () => import('@/views/user/subPage/EntityApply.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/user/subPage/EntityApply/StepOne'),
            name: 'EntityApplicationOne',
            meta: {
              roles: ['entity']
            }
          },
          {
            path: '2',
            component: () => import('@/views/user/subPage/EntityApply/StepTwo'),
            name: 'EntityApplicationTwo',
            meta: {
              roles: ['entity']
            }
          }, {
            path: '3',
            component: () => import('@/views/user/subPage/EntityApply/StepThree'),
            name: 'EntityApplicationThree',
            meta: {
              roles: ['entity']
            }
          }, {
            path: '4',
            component: () => import('@/views/user/subPage/EntityApply/StepFour'),
            name: 'EntityApplicationFour',
            meta: {
              roles: ['entity']
            }
          }, {
            path: '5',
            component: () => import('@/views/user/subPage/EntityApply/StepFive'),
            name: 'EntityApplicationFive',
            meta: {
              roles: ['entity']
            }
          },
          {
            path: '6',
            component: () => import('@/views/user/subPage/EntityApply/StepSix'),
            name: 'EntityApplicationSix',
            meta: {
              roles: ['entity']
            }
          }
        ]
      }
    ]
  },
  {
    path: '/broker',
    component: () => import('@/views/broker/index.vue'),
    meta: {
      roles: ['broker']
    },
    children: [
      {
        path: '',
        name: 'BrokerDash',
        component: () => import('@/views/broker/subPage/Dash.vue'),
        meta: {
          roles: ['broker']
        }
      },
      {
        path: 'promotion-link',
        component: () => import('@/views/broker/subPage/PromotionLink.vue'),
        name: 'PromotionLink',
        meta: {
          roles: ['broker']
        }
      },
      {
        path: 'customers',
        component: () => import('@/views/broker/subPage/Customers.vue'),
        name: 'Customers',
        meta: {
          roles: ['broker']
        }
      },
      {
        path: 'commission',
        component: () => import('@/views/broker/subPage/Commission.vue'),
        name: 'Commission',
        meta: {
          roles: ['broker']
        }
      },
      {
        path: 'live-rate',
        component: () => import('@/views/user/subPage/LiveRate.vue'),
        name: 'Live Rate',
        meta: {
          roles: ['broker']
        }
      },
      {
        path: 'settings',
        component: () => import('@/views/broker/subPage/Settings.vue'),
        name: 'Settings',
        meta: {
          roles: ['broker']
        }
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if ((from.query.id && !to.query.id) || (from.query.source && !to.query.source)) {
    if (to.path === from.path) {
      return
    }
    next({ path: to.path, query: { id: from.query.id, source: from.query.source } })
  }
  if (to.meta && to.meta.roles && to.meta.roles[0] === 'user') {
    if (hasLocalStorage('token')) {
      if (from.meta && from.meta.roles) {
        if (to.meta.roles[0] !== from.meta.roles[0]) {
          getCustomerProfile().then((res) => {
            if (res.status.login_type === 'I') { next() }
            else if (res.status.login_type === 'E') { next() }
            else {
              next({ name: 'SmsLogin' })
            }
          })
            .catch(() => {
              removeLocalStorage('token')
              message.error('Please log in first')
              next({ name: 'SmsLogin' })
            })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      message.error('Please log in first')
      next({ name: 'SmsLogin' })
    }
  } else if (to.name === 'SmsLogin') {
    if (!to.query.id) {
      if (hasLocalStorage('token')) {
        const type = JSON.parse(getLocalStorage('token')).type || null
        if (type === 'I') {
          next({ name: 'UserPage' })
        } else if (type === 'E') {
          next({ name: 'UserPage' })
        }
        else if (type === 'R') {
          next({ name: 'BrokerDash' })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      removeLocalStorage('token')
      next()
    }
  } else {
    next()
  }
})

export default router
