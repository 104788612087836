/* eslint-disable */

// import { getLocalStorage } from '@/utils/localStorage'

const personStepOne = {
  namespaced: true,
  state: {
    first_name: null,
    last_name: null,
    personal_other_names_and_aliases: null,
    personal_dial_code: null,
    email_address: null,
    title: null,
    mobile_phone: null,
    gender: null,
    date_of_birth: null,
    place_of_birth: null,
    identification_type: null,
    suburb: null,
    issue_country: null,
    issue_date: null,
    nationality: null,
    expiry_date: null,
    id_num: null,
    is_nz_tax_resident: null,
    nz_ird_num: null,
    city: null,
    country: null,
    postcode: null,
    address_prove_type: null,
    employer_company_name: null,
    business_registration: null,
    business_email: null,
    employer_company_address: null,
    business_engaged_industry: null,
    residential_address: null,
    employer_company_city: null,
    employer_company_country: null,
    employer_company_post_code: null,
    employer_job_title: null,
    employer_phone_number: null,
    us_taxer_file_list: null,
    passport_file_list: null,
    nationalID_file_list: null,
    has_other_country_tax: null,
    driver_license_file_list: null,
    bank_stament_address_file_list: null,
    utility_address_file_list: null,
    government_correspondence_address_file_list: null,
    commission_correspondence_address_file_list: null,
    tenancy_agreement_address_file_list: null,
    vehicle_register_address_file_list: null,
    employment_status: null,
    nz_resident: null,

    prove_channel: null,
    panes:[{ title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }],
    face_to_face_type: null,
    none_face_to_face_type: null,


    // nz驾照
    nz_au_driver_front_file_list: null,
    nz_au_driver_back_file_list: null,
    nz_au_driver_num: null,
    nz_au_driver_country: null,
    nz_au_driver_issue_date: null,
    nz_au_driver_expiry_date: null,
    
    //nz护照
    nz_au_passport_file_list: null,
    nz_au_passport_num: null,
    nz_au_passport_country: null,
    nz_au_passport_issue_date: null,
    nz_au_passport_expiry_date: null,

    // 中国银行卡
    cn_bank_front_file_list: null,
    cn_bank_front_num: null,
    cn_bank_front_country: 'CN',
    cn_bank_issue_date: null,
    cn_bank_expiry_date: null,

    //cn护照
    cn_passport_file_list: null,
    cn_passport_num: null,
    cn_passport_country: 'CN',
    cn_passport_issue_date: null,
    cn_passport_expiry_date: null,
    cn_passport_visa_file_list: null,
    
    //cn 护照option
    cn_passport_not_required_file_list: null,
    cn_passport_not_required_num: null,
    cn_passport_not_required_country: 'CN',
    cn_passport_not_required_issue_date: null,
    cn_passport_not_required_expiry_date: null,

    cn_passport_not_required_visa_file_list: null,

    //cn 身份证
    cn_identify_front_file_list: null,
    cn_identify_front_num: null,
    cn_identify_front_country: 'CN',
    cn_identify_back_file_list: null,
    cn_identify_issue_date: null,
    cn_identify_expiry_date: null,

    //其他护照
    other_identify_file_list: null,
    other_identify_num: null,
    other_identify_country: null,
    other_identify_issue_date: null,
    other_identify_expiry_date: null,

    other_passport_visa_file_list: null,
    tax_residence_us_person: null,

    //出生证明
    birth_certificate_num: null,
    birth_certificate_country: null,
    birth_certificate_file_list: null,
     
    //nz citizenship
    nz_citizenship_num: null,
    nz_citizenship_country: 'NZ',
    nz_citizenship_issue_date: null,
    nz_citizenship_expiry_date: null,
    nz_citizenship_front_file_list: null,
    nz_citizenship_back_file_list: null,

    //other national ID
    other_national_id_num: null,
    other_national_id_country: null,
    other_national_id_issue_date: null,
    other_national_id_expiry_date: null,
    other_national_id_front_file_list: null,
    other_national_id_back_file_list: null,

  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.content, payload.value)
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.content, JSON.stringify(payload.value))
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(
        key=>{state[key]=payload[key]})
    }
  },
  actions: {
    ChangeFormAction (content, payload) {
      content.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    },
    ReSetFirstFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initFirstFormAction(context, data) {
      context.commit('SetAllState', {...initial, ...data})
    }
  },

  getters: {
    GetStepOne: (state, getter) => {
      return {...state, has_us_taxer: getter.has_other_country_tax}
    },
    has_us_taxer: state =>{
      if(state.panes){
        const res = state.panes.findIndex((item) => {
        return (item.content.other_tax_contry === 'US')
      })
      if(res > -1 ){
        return true
      }else{
        return null
      }
      }
      return null
    }
  }
}
const initial ={
    first_name: null,
    last_name: null,
    personal_other_names_and_aliases: null,
    email_address: null,
    title: null,
    gender: null,
    date_of_birth: null,
    place_of_birth: null,
    passport_num: null,
    nationality: null,
    nationID_driver_license_num: null,
    driver_license_version: null,
    is_nz_tax_resident: null,
    expiry_date: null,
    issue_date: null,
    issue_country: null,
    id_num: null,
    identification_type: null,
    suburb: null,
    nz_ird_num: null,
    city: null,
    country: null,
    postcode: null,
    address_prove_type: null,
    employer_company_name: null,
    business_registration: null,
    business_email: null,
    employer_company_address: null,
    business_engaged_industry: null,
    residential_address: null,
    employer_company_city: null,
    employer_company_country: null,
    employer_company_post_code: null,
    employer_job_title: null,
    employer_phone_number: null,
    us_taxer_file_list: null,
    passport_file_list: null,
    nationalID_file_list: null,
    has_other_country_tax: null,
    driver_license_file_list: null,
    bank_stament_address_file_list: null,
    utility_address_file_list: null,
    government_correspondence_address_file_list: null,
    commission_correspondence_address_file_list: null,
    tenancy_agreement_address_file_list: null,
    vehicle_register_address_file_list: null,
    employment_status: null,
    nz_resident: null,

    prove_channel: null,
    panes:[{ title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }],
    face_to_face_type: null,
    none_face_to_face_type: null,


    // nz驾照
    nz_au_driver_front_file_list: null,
    nz_au_driver_back_file_list: null,
    nz_au_driver_num: null,
    nz_au_driver_country: null,
    nz_au_driver_issue_date: null,
    nz_au_driver_expiry_date: null,

    //nz护照
    nz_au_passport_file_list: null,
    nz_au_passport_num: null,
    nz_au_passport_country: null,
    nz_au_passport_issue_date: null,
    nz_au_passport_expiry_date: null,

    // 中国银行卡
    cn_bank_front_file_list: null,
    cn_bank_front_num: null,
    cn_bank_front_country: 'CN',
    cn_bank_issue_date: null,
    cn_bank_expiry_date: null,


    //cn护照
    cn_passport_file_list: null,
    cn_passport_num: null,
    cn_passport_country: 'CN',
    cn_passport_issue_date: null,
    cn_passport_expiry_date: null,

    cn_passport_visa_file_list: null,
    
    //cn 护照option
    cn_passport_not_required_file_list: null,
    cn_passport_not_required_num: null,
    cn_passport_not_required_country: 'CN',
    cn_passport_not_required_issue_date: null,
    cn_passport_not_required_expiry_date: null,

    cn_passport_not_required_visa_file_list: null,

    //cn 身份证
    cn_identify_front_file_list: null,
    cn_identify_front_num: null,
    cn_identify_front_country: 'CN',
    cn_identify_issue_date: null,
    cn_identify_expiry_date: null,

    cn_identify_back_file_list: null,

    //其他护照
    other_identify_file_list: null,
    other_identify_num: null,
    other_identify_country: null,
    other_identify_issue_date: null,
    other_identify_expiry_date: null,

    other_passport_visa_file_list: null,

    //出生证明
    birth_certificate_num: null,
    birth_certificate_country: null,
    birth_certificate_file_list: null,
    
    //nz citizenship
    nz_citizenship_num: null,
    nz_citizenship_country: 'NZ',
    nz_citizenship_issue_date: null,
    nz_citizenship_expiry_date: null,
    nz_citizenship_front_file_list: null,
    nz_citizenship_back_file_list: null,

    //other national ID
    other_national_id_num: null,
    other_national_id_country: null,
    other_national_id_issue_date: null,
    other_national_id_expiry_date: null,
    other_national_id_front_file_list: null,
    other_national_id_back_file_list: null,

    tax_residence_us_person: null,
}
export default personStepOne
