import Vue from 'vue'

const entityStepThree = {
  namespaced: true,
  state: {
    director_panes: [
      {
        title: 'Person Detail 成员信息 #1',
        key: 1,
        closable: false,
        entity_director_postion: null,
        entity_director_title: null,
        entity_director_first_name: null,
        entity_director_last_name: null,
        entity_director_gender: null,
        entity_director_dob: null,
        entity_director_nationality: null,
        entity_director_place_of_birth: null,
        entity_director_tax_country: null,
        entity_director_tax_num: null,
        entity_director_address: null,
        entity_director_country: null,
        entity_director_city: null,
        entity_director_suburb: null,
        entity_director_postcode: null,
        entity_director_dialcode: null,
        entity_director_phonenum: null,
        entity_director_email: null,
        entity_director_identification_type: null,
        entity_director_id_num: null,
        entity_director_issue_date: null,

        entity_director_id_proveChannel: null,
        entity_director_id_proveMethod: null,

        entity_director_nz_au_passport_num: null,
        entity_director_nz_au_passport_country: null,
        entity_director_nz_au_passport_issue_date: null,
        entity_director_nz_au_passport_expiry_date: null,
        entity_director_nz_au_passport_file: null,
        entity_director_nz_au_driver_num: null,
        entity_director_nz_au_driver_country: null,
        entity_director_nz_au_driver_issue_date: null,
        entity_director_nz_au_driver_expiry_date: null,
        entity_director_nz_au_driver_front_file: null,
        entity_director_nz_au_driver_back_file: null,
        entity_director_cn_passport_num: null,
        entity_director_cn_passport_country: 'CN',
        entity_director_cn_passport_issue_date: null,
        entity_director_cn_passport_expiry_date: null,
        entity_director_cn_passport_file: null,
        entity_director_cn_passport_visa_file: null,
        entity_director_cn_identify_num: null,
        entity_director_cn_identify_country: 'CN',
        entity_director_cn_identify_issue_date: null,
        entity_director_cn_identify_expiry_date: null,
        entity_director_cn_identify_front_file: null,
        entity_director_cn_identify_back_file: null,
        entity_director_cn_bank_num: null,
        entity_director_cn_bank_country: 'CN',
        entity_director_cn_bank_issue_date: null,
        entity_director_cn_bank_expiry_date: null,
        entity_director_cn_bank_file: null,
        entity_director_cn_passport_option_num: null,
        entity_director_cn_passport_option_country: 'CN',
        entity_director_cn_passport_option_issue_date: null,
        entity_director_cn_passport_option_expiry_date: null,
        entity_director_cn_passport_option_file: null,
        entity_director_cn_passport_visa_option_file: null,
        entity_director_other_passport_option_num: null,
        entity_director_other_passport_option_country: null,
        entity_director_other_passport_option_issue_date: null,
        entity_director_other_passport_option_expiry_date: null,
        entity_director_other_passport_option_file: null,
        entity_director_other_passport_visa_option_file: null,
        entity_director_birth_certificate_num: null,
        entity_director_birth_certificate_country: null,
        entity_director_birth_certificate_file: null,
        entity_director_nz_citizenship_num: null,
        entity_director_nz_citizenship_country: 'NZ',
        entity_director_nz_citizenship_issue_date: null,
        entity_director_nz_citizenship_expiry_date: null,
        entity_director_nz_citizenship_front_file: null,
        entity_director_nz_citizenship_back_file: null,
        entity_director_other_national_id_num: null,
        entity_director_other_national_id_country: null,
        entity_director_other_national_id_issue_date: null,
        entity_director_other_national_id_expiry_date: null,
        entity_director_other_national_id_front_file: null,
        entity_director_other_national_id_back_file: null,

        entity_director_addressProveType: null,
        entity_director_address_file_list: null

      }
    ]
  },
  mutations: {
    SetFormMutation (state, payload) {
      if (!state.director_panes[payload.index][payload.variable]) {
        Vue.set(state.director_panes[payload.index], payload.variable, payload.value)
      }
      state.director_panes[payload.index][payload.variable] = payload.value
    },
    SetPaneMutaition (state, payload) {
      state.director_panes = payload
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key => {
        state[key] = payload[key]
      })
    }
  },
  actions: {
    handleChangeAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    handleChangePane (context, payload) {
      context.commit('SetPaneMutaition', payload)
    },
    ReSetThirdFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initThirdFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    getPanesLength (state) {
      return state.director_panes.length
    },
    getThreeData: (state) => {
      return state
    }
  }
}
const initial = {
  director_panes: [
    {
      title: 'Person Detail 成员信息 #1',
      key: 1,
      closable: false,
      entity_director_postion: null,
      entity_director_title: null,
      entity_director_first_name: null,
      entity_director_last_name: null,
      entity_director_gender: null,
      entity_director_dob: null,
      entity_director_nationality: null,
      entity_director_place_of_birth: null,
      entity_director_tax_country: null,
      entity_director_tax_num: null,
      entity_director_address: null,
      entity_director_country: null,
      entity_director_city: null,
      entity_director_suburb: null,
      entity_director_postcode: null,
      entity_director_dialcode: null,
      entity_director_phonenum: null,
      entity_director_email: null,
      entity_director_identification_type: null,
      entity_director_id_num: null,
      entity_director_issue_date: null,
      entity_director_expiry_date: null,
      entity_director_issue_country: null,

      entity_director_id_proveChannel: null,
      entity_director_id_proveMethod: null,

      entity_director_nz_au_passport_num: null,
      entity_director_nz_au_passport_country: null,
      entity_director_nz_au_passport_issue_date: null,
      entity_director_nz_au_passport_expiry_date: 'null',
      entity_director_nz_au_passport_file: null,
      entity_director_nz_au_driver_num: null,
      entity_director_nz_au_driver_country: null,
      entity_director_nz_au_driver_issue_date: null,
      entity_director_nz_au_driver_expiry_date: null,
      entity_director_nz_au_driver_front_file: null,
      entity_director_nz_au_driver_back_file: null,
      entity_director_cn_passport_num: null,
      entity_director_cn_passport_country: 'CN',
      entity_director_cn_passport_issue_date: null,
      entity_director_cn_passport_expiry_date: null,
      entity_director_cn_passport_file: null,
      entity_director_cn_passport_visa_file: null,
      entity_director_cn_identify_num: null,
      entity_director_cn_identify_country: 'CN',
      entity_director_cn_identify_issue_date: null,
      entity_director_cn_identify_expiry_date: null,
      entity_director_cn_identify_front_file: null,
      entity_director_cn_identify_back_file: null,
      entity_director_cn_bank_num: null,
      entity_director_cn_bank_country: 'CN',
      entity_director_cn_bank_issue_date: null,
      entity_director_cn_bank_expiry_date: null,
      entity_director_cn_bank_file: null,
      entity_director_cn_passport_option_num: null,
      entity_director_cn_passport_option_country: 'CN',
      entity_director_cn_passport_option_issue_date: null,
      entity_director_cn_passport_option_expiry_date: null,
      entity_director_cn_passport_option_file: null,
      entity_director_cn_passport_visa_option_file: null,
      entity_director_other_passport_option_num: null,
      entity_director_other_passport_option_country: null,
      entity_director_other_passport_option_issue_date: null,
      entity_director_other_passport_option_expiry_date: null,
      entity_director_other_passport_option_file: null,
      entity_director_other_passport_visa_option_file: null,
      entity_director_birth_certificate_num: null,
      entity_director_birth_certificate_country: null,
      entity_director_birth_certificate_file: null,
      entity_director_nz_citizenship_num: null,
      entity_director_nz_citizenship_country: 'NZ',
      entity_director_nz_citizenship_issue_date: null,
      entity_director_nz_citizenship_expiry_date: null,
      entity_director_nz_citizenship_front_file: null,
      entity_director_nz_citizenship_back_file: null,
      entity_director_other_national_id_num: null,
      entity_director_other_national_id_country: null,
      entity_director_other_national_id_issue_date: null,
      entity_director_other_national_id_expiry_date: null,
      entity_director_other_national_id_front_file: null,
      entity_director_other_national_id_back_file: null,

      entity_director_addressProveType: null,
      entity_director_address_file_list: null
    }
  ]
}
export default entityStepThree
