// import { vertifyBoolean } from '@/utils/validation'
// import { getLocalStorage } from '@/utils/localStorage'
// import { getHashCode } from '@/utils/hashUser'

// const hashGetLocal = function (val) {
//   return getLocalStorage(getHashCode() + val)
// }
const personStepTwo = {
  namespaced: true,
  state: {
    source_of_funds_text: null,
    source_of_funds_other_text: null,
    purpose_of_transaction_text: null,
    purpose_of_transaction_other_text: null,
    transaction_country_list: null,
    num_of_anual_transactions: null,
    frequency_of_transaction: null,
    total_volumn_of_trasaction_per_year: null,
    average_volume_of_per_transaction: null,
    is_money_from_high_risk_country: null,
    money_from_high_risk_country_specific: null,
    had_trade_with_high_rish_country: null,
    trade_with_high_rish_country_specific: null,
    is_pay_for_thrid_party: null,
    pay_for_thrid_party_specific: null,
    aml_announcement_check: null
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.content, payload.value)
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key => {
        state[key] = payload[key]
      })
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ReSetSecondFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initSecondFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    GetStepTwo: (state) => {
      return state
    }
  }
}
const initial = {
  source_of_funds_other_text: null,
  source_of_funds_text: null,
  purpose_of_transaction_text: null,
  transaction_country_list: null,
  num_of_anual_transactions: null,
  purpose_of_transaction_other_text: null,
  frequency_of_transaction: null,
  total_volumn_of_trasaction_per_year: null,
  average_volume_of_per_transaction: null,
  is_money_from_high_risk_country: null,
  money_from_high_risk_country_specific: null,
  had_trade_with_high_rish_country: null,
  trade_with_high_rish_country_specific: null,
  is_pay_for_thrid_party: null,
  pay_for_thrid_party_specific: null,
  aml_announcement_check: null
}
export default personStepTwo
