import axios from 'axios'
import { getLocalStorage } from '@/utils/localStorage'
import './req_intercept'
import './res_intercept'
import QS from 'qs'
// import { checkToken } from '@/utils/checkToken'

if (process.env.VUE_APP_BASE_API) {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_API
} else {
  axios.defaults.baseURL = 'http://nzsky.test'
}

axios.defaults.timeout = 5000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
const CCAxios = axios.create({
  baseURL: process.env.VUE_APP_CC_BASE_URL,
  timeout: 8000
})

CCAxios.interceptors.response.use(
  // axios 响应拦截
  response => {
    if (response.status === 200) {
      if (response.data.error_code === 0 && response.data.message === 'OK') {
        return Promise.resolve(response.data.data)
      } else if (response.data.error_code === 103) {
        return Promise.reject(response.data)
      } else {
        return Promise.reject(response.data)
      }
    } else {
      return Promise.reject(response.data)
    }
  },
  error => {
    console.log(error)
    // removeLocalStorage('token')
    // Router.push('/login')
    return Promise.reject(error.data)
  }
)

export function post (url, params, auth = false, Qs = true) {
  const tokenHeader = {
    headers: {
      Authorization: 'Bearer ' + JSON.parse(getLocalStorage('token'))?.access_token
    }
  }
  if (!auth) {
    if (Qs) {
      return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params)).then(
          res => {
            resolve(res)
          }
        ).catch(err => {
          reject(err)
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        axios.post(url, params).then(
          res => {
            resolve(res)
          }
        ).catch(err => {
          reject(err)
        })
      })
    }
  } else {
    return new Promise((resolve, reject) => {
      axios.post(url, params, tokenHeader).then(
        res => {
          resolve(res)
        }
      ).catch(err => {
        reject(err)
      })
    })
  }
}
export function flexPost (url, params, auth = false, Qs = true) {
}
export function get (url, params = {}, auth = false, option = {}) {
  const localToken = getLocalStorage('token')
  const tmp = typeof (localToken) === 'string' ? JSON.parse(localToken) : {}
  const token = {
    Authorization: 'Bearer ' + tmp.access_token,
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
  if (!auth) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: params,
        headers: {
          ...token
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: params,
        headers: {
          ...token
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
export function ccGet (url, params = {}, auth = false, option = {}) {
  const localToken = getLocalStorage('token')
  const tmp = typeof (localToken) === 'string' ? JSON.parse(localToken) : {}
  const token = {
    Authorization: 'Bearer ' + tmp.access_token,
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
  if (!auth) {
    return new Promise((resolve, reject) => {
      CCAxios.get(url, {
        params: params,
        headers: {
          ...token
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      CCAxios.get(url, {
        params: params,
        headers: {
          ...token
        }
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export function ccPost (url, params, auth = false, Qs = true) {
  const tokenHeader = {
    headers: {
      Authorization: 'Bearer ' + JSON.parse(getLocalStorage('token'))?.access_token
    }
  }
  if (!auth) {
    if (Qs) {
      return new Promise((resolve, reject) => {
        CCAxios.post(url, QS.stringify(params)).then(
          res => {
            resolve(res)
          }
        ).catch(err => {
          reject(err)
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        CCAxios.post(url, params).then(
          res => {
            resolve(res)
          }
        ).catch(err => {
          reject(err)
        })
      })
    }
  } else {
    return new Promise((resolve, reject) => {
      CCAxios.post(url, params, tokenHeader).then(
        res => {
          resolve(res)
        }
      ).catch(err => {
        reject(err)
      })
    })
  }
}
