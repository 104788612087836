const initData = {
  beneficiary_type: 'individual',
  first_name: '',
  last_name: '',
  email: '',
  beneficiary_address: '',
  beneficiary_city: '',
  beneficiary_country: '',
  beneficiary_company_name: '',
  // step2
  currency: '',
  bank_account_holder_name: '',
  bank_country: '',
  account_number: '',
  bic_swift: '',
  default_beneficiary: 0
}
export default {
  namespaced: true,
  state: {
    formData: {
      beneficiary_type: '',
      first_name: '',
      last_name: '',
      beneficiary_address: '',
      beneficiary_city: '',
      beneficiary_country: '',
      beneficiary_company_name: '',
      // step2
      currency: '',
      bank_account_holder_name: '',
      bank_country: '',
      account_number: '',
      bic_swift: '',
      default_beneficiary: 0
    }
  },
  mutations: {
    updateFormData (state, payload) {
      state.formData[payload.content] = payload.value
    },
    resetFormMut (state, payload) {
      state.formData = payload
    }
  },
  actions: {
    ChangeFormAction (content, payload) {
      content.commit('updateFormData', payload)
    },
    ResetFormAction (content) {
      content.commit('resetFormMut', initData)
    }
  },
  getters: {
    GetFormData: (state) => {
      // eslint-disable-next-line camelcase
      const { first_name, last_name } = state.formData
      // eslint-disable-next-line camelcase
      const name = `${first_name} ${last_name}`
      return { ...state.formData, name }
    }
  }
}
